export const editorClaimList = [
    {
        title: 'unlimitedTranscription',
        apiName: 'paidFeatures:enabled',
    },
    {
        title: 'subtitleMode',
        apiName: 'editor:subtitleMode',
    },
    {
        title: 'sectionHeadingsTesting',
        apiName: 'editor:sectionHeadingsTesting',
    },
    {
        title: 'sharingToAll',
        apiName: 'sharing:all',
    },
    {
        title: 'accountActive',
        apiName: 'account:active',
    },
    {
        title: 'smartTranslation',
        apiName: 'services:translate',
    },
    {
        title: 'linkApp',
        apiName: 'services:link',
    },
    {
        title: 'transcode',
        apiName: 'services:transcode',
    },
    {
        title: 'summaries',
        apiName: 'services:summarize',
    },
    {
        title: 'dataThemeApa',
        apiName: 'dataTheme:apa',
    },
    {
        title: 'signInApa',
        apiName: 'signIn:apa',
    },
    {
        title: 'keywords',
        apiName: 'keywords:enabled',
    },
    {
        title: 'abbreviations',
        apiName: 'editor:abbreviations',
    },
    {
        title: 'apiToken',
        apiName: 'account:apiToken',
    },
    {
        title: 'trashedProjects',
        apiName: 'projects:showTrashed',
    },
    {
        title: 'unlimitedStorage',
        apiName: 'unlimitedStorage:enabled',
    },
    {
        title: 'unlimitedTeamSize',
        apiName: 'unlimitedTeamSize:enabled',
    },
];
export const subtitlesClaimList = [
    {
        title: 'srtSubtitles',
        apiName: 'subtitles:srt',
    },
    {
        title: 'vttSubtitles',
        apiName: 'subtitles:vtt',
    },
    {
        title: 'ssaSubtitles',
        apiName: 'subtitles:ssa',
    },
    {
        title: 'stlSubtitles',
        apiName: 'subtitles:stl',
    },
    {
        title: 'ttmlSubtitles',
        apiName: 'subtitles:ttml',
    },
];
export const accountClaimList = [
    {
        title: 'subtitleMode',
        apiName: 'editor:subtitleMode',
    },
    {
        title: 'sharingToAll',
        apiName: 'sharing:all',
    },
    {
        title: 'smartTranslation',
        apiName: 'services:translate',
    },
    {
        title: 'linkApp',
        apiName: 'services:link',
    },
    {
        title: 'transcode',
        apiName: 'services:transcode',
    },
    {
        title: 'summaries',
        apiName: 'services:summarize',
    },
    {
        title: 'keywords',
        apiName: 'keywords:enabled',
    },
];
