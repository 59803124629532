import { createProjectFromApi, } from 'api/model/project';
import { checkObjectsMatch } from 'libs/utils';
import { ApiError, blob, json, text, } from '@newtontechnologies/beey-api-js-client/receivers';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { serializeToVttFormat } from 'libs/vtt-format-parser';
import { BeeyLocale } from 'libs/locales/locale';
import { subSystemList } from './websocket-api';
const PAGE_LIMIT = (2 ** 31) - 1;
export const isSearchingProjectsQuery = (query) => ('searching' in query && query.searching === true);
export const isSearchedProject = (project) => ('searched' in project && project.searched === true);
const buildProjectsList = (apiList, query) => {
    const items = apiList.list.map((project) => (Object.assign(Object.assign({}, createProjectFromApi(project)), { queued: apiList.queued.includes(project.id), processingStateDetail: project.processingState === 'InProgress'
            ? {
                uploading: apiList.uploading.includes(project.id),
                transcribed: apiList.transcribing.includes(project.id) ? 'unknown' : 'none',
            }
            : null })));
    return {
        items,
        query,
        totalCount: apiList.totalCount,
    };
};
const buildSearchedProjectsList = (apiList, query) => {
    const simpleList = buildProjectsList(apiList, query);
    const items = apiList.list.map((project, index) => (Object.assign(Object.assign({}, simpleList.items[index]), { searched: true, notesHighlight: project.notesHighlight, projectNameHighlight: project.projectNameHighlight, speakerHighlight: project.speakerHighlight, transcriptionHighlight: project.transcriptionHighlight })));
    return {
        items,
        query,
        totalCount: apiList.totalCount,
    };
};
const fetchBasicProjectsList = async (connection, query) => {
    const { currentPage = 1, pageSize = PAGE_LIMIT, orderon = 'created', orderby = 'descending', tags = null, createdFrom, createdTo, updatedFrom, updatedTo, } = query;
    const url = apiV2.projects.search.url({
        skip: pageSize * (currentPage - 1),
        count: pageSize,
        orderon,
        orderby,
        from: updatedFrom,
        to: updatedTo,
        createdFrom,
        createdTo,
        tag: tags,
    });
    return buildProjectsList(await connection.authFetch()
        .url(url)
        .post()
        .receive(json())
        .fetch(), query);
};
export const fetchSearchedProjectsList = async (connection, query) => {
    const { currentPage = 1, pageSize = PAGE_LIMIT, orderon = 'created', orderby = 'descend', tags = null, all = '', projectname = '', transcription = '', speakers = '', notes = '', createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.fullTextSearch.url({
        allFullText: all,
        ProjectNameFullText: projectname,
        TranscriptionFullText: transcription,
        SpeakerFullText: speakers,
        NotesFullText: notes,
        Skip: pageSize * (currentPage - 1),
        Count: pageSize,
        Orderon: orderon,
        Orderby: orderby,
        UpdatedFrom: updatedFrom,
        UpdatedTo: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        tag: tags,
    });
    return buildSearchedProjectsList(await connection.authFetch()
        .url(url)
        .post()
        .receive(json())
        .fetch(), query);
};
export const fetchProjectsList = async (connection, query) => (isSearchingProjectsQuery(query)
    ? fetchSearchedProjectsList(connection, query)
    : fetchBasicProjectsList(connection, query));
export const fetchAllProjectTags = (connection) => connection.authFetch()
    .url(apiV2.projects.allTags.url())
    .post()
    .receive(json())
    .fetch();
export const fetchProjectsCount = async (connection) => {
    const apiList = await connection.authFetch()
        .url(apiV2.projects.search.url({ count: 0 }))
        .post()
        .receive(json())
        .fetch();
    return apiList.totalCount;
};
export const fetchProject = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).url())
    .receive(json()
    .map(createProjectFromApi)
    .catchHttpError((response) => {
    if (response.status === 404 || response.status === 403) {
        return 'not-found';
    }
    throw new ApiError(response);
}))
    .fetch();
const withAccessToken = (url, accessToken) => {
    const modifiedUrl = new URL(url);
    modifiedUrl.searchParams.set('accessToken', String(accessToken));
    return modifiedUrl.toString();
};
/* NOTE: Update project with retry on access token conflict.
   If server project matches expectedProjectMask, conflict is automatically
   resolved. Otherwise ApiError is raised. Values omitted
   in expectedProjectMask are not checked. Provide empty project to ignore
   force update without checking for conflicts.
*/
export const safelyUpdateProject = async (connection, url, project, projectFetch, expectedProjectMask = {}) => {
    try {
        const result = await projectFetch
            .url(withAccessToken(url, project.accessToken))
            .fetch();
        return result;
    }
    catch (error) {
        if (error instanceof ApiError && error.response.status === 409) {
            const serverProject = await fetchProject(connection, project.id);
            if (serverProject === 'not-found') {
                global.logger.error('project not found during conflict resolution');
                throw new Error('project not found during conflict resolution');
            }
            if (checkObjectsMatch(serverProject, expectedProjectMask)) {
                global.logger.info('accessToken conflict resolved automatically');
                return projectFetch
                    .url(withAccessToken(url, serverProject.accessToken))
                    .fetch();
            }
            global.logger.error('true conflict when changing project', {}, error);
        }
        throw error;
    }
};
export const setupNewProject = (connection, projectName) => connection.authFetch()
    .url(apiV2.projects.url())
    .postJson({
    Name: projectName,
    CustomPath: '/',
})
    .receive(json().map(createProjectFromApi))
    .fetch();
export const updateProjectName = async (connection, project, newName) => {
    const projectFetch = connection.authFetch()
        .postJson({
        Name: newName,
    })
        .receive(json().map(createProjectFromApi));
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).name.url(), project, projectFetch, { name: project.name });
};
export const fetchProjectMetadata = async (connection, projectId, key) => {
    const metadata = await connection.authFetch()
        .url(apiV2.projects.id(projectId).metadata.url({ key }))
        .receive(json())
        .fetch();
    return metadata === null ? null : metadata.value;
};
export const fetchProjectLanguage = async (connection, project) => {
    var _a, _b;
    const configLanguage = (_b = (_a = project.transcriptionConfig) === null || _a === void 0 ? void 0 : _a.language) !== null && _b !== void 0 ? _b : null;
    if (configLanguage !== null) {
        return configLanguage;
    }
    global.logger.error('project is missing config language');
    const fetchedLanguage = await fetchProjectMetadata(connection, project.id, 'language');
    const locale = fetchedLanguage === null ? null : BeeyLocale.fromCode(fetchedLanguage);
    if (locale !== null) {
        return locale;
    }
    global.logger.error('project is missing both config and metadata language');
    return null;
};
export const fetchHeadingsMetadata = async (connection, project) => {
    const headingsMetadata = await fetchProjectMetadata(connection, project.id, 'headings');
    if (headingsMetadata === null) {
        return null;
    }
    try {
        if (typeof headingsMetadata === 'string') {
            // NOTE: this is for legacy testing projects, this format was never used in
            // production, remove after version 55.
            return JSON.parse(headingsMetadata);
        }
    }
    catch (error) {
        global.logger.error('failed to parse metadata', { headingsMetadata }, error);
        return null;
    }
    return headingsMetadata;
};
export const fetchSecondaryProjectId = async (connection, project) => {
    const secondaryProjectId = await fetchProjectMetadata(connection, project.id, 'secondaryProjectId');
    if (secondaryProjectId === null) {
        return null;
    }
    return String(secondaryProjectId);
};
export const addTagToProject = async (connection, project, tag) => {
    const projectFetch = connection.authFetch()
        .postJson({ Tag: tag })
        .receive(json().map(createProjectFromApi));
    return (safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, projectFetch, { tags: project.tags }));
};
export const deleteTagFromProject = async (connection, project, tag) => {
    const projectFetch = connection.authFetch()
        .postJson({ Tag: tag })
        .delete()
        .receive(json().map(createProjectFromApi));
    return (safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, projectFetch, { tags: project.tags }));
};
export const updateTags = async (connection, project, tags) => {
    const projectFetch = connection.authFetch()
        .put(JSON.stringify({ Tags: tags }))
        .headers({ 'Content-Type': 'application/json' })
        .receive(json().map(createProjectFromApi));
    return (safelyUpdateProject(connection, apiV2.projects.id(project.id).tags.url(), project, projectFetch, { tags: project.tags }));
};
export const fetchTags = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).tags.url())
    .receive(json())
    .fetch();
export const updateProjectMetadata = (connection, project, key, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key,
}))
    .postJson(value)
    .receive(json().map(createProjectFromApi))
    .fetch();
export const deleteProjectMetadata = (connection, project, key) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key,
}))
    .delete()
    .receive(json().map(createProjectFromApi))
    .fetch();
export const updateProjectNotes = (connection, project, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key: 'notes',
}))
    // NOTE: We send all metadata as JSON, even if it's just a string that is stringified.
    .postJson(value)
    .receive(json().map(createProjectFromApi))
    .fetch();
export const updateProjectHeadingsMetadata = (connection, project, value) => connection.authFetch()
    .url(apiV2.projects.id(project.id).metadata.url({
    accessToken: project.accessToken,
    key: 'headings',
}))
    .postJson(value)
    .receive(json().map(createProjectFromApi))
    .fetch();
export const uploadTrsx = async (connection, project, trsx, variant) => {
    const lz4 = lz4_require('lz4');
    const { Buffer } = lz4_require('buffer');
    const input = trsx instanceof File
        ? Buffer.from(await trsx.arrayBuffer())
        : Buffer.from(trsx);
    const output = lz4.encode(input, { blockChecksum: false });
    const trsxBlob = new Blob([output], { type: 'application/octet-stream' });
    const projectFetch = connection.authFetch()
        .postBlob(trsxBlob)
        .receive(json().map(createProjectFromApi));
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).files[variant].url({
        accessToken: project.accessToken,
        isCompressed: true,
    }), project, projectFetch, { currentTrsxId: project.currentTrsxId });
};
export const fetchTrsx = (connection, project, variant, fileId) => connection.authFetch()
    .url(apiV2.projects.id(project.id).files[variant].url({ fileId }))
    .receive(text())
    .fetch();
export const fetchProjectTrsx = async (connection, project) => {
    try {
        const trsx = await fetchTrsx(connection, project, 'currentTrsx');
        return trsx;
    }
    catch (_a) {
        void 0;
    }
    return fetchTrsx(connection, project, 'originalTrsx');
};
export const shareProject = async (connection, project, email) => safelyUpdateProject(connection, apiV2.projects.id(project.id).sharings.url(), project, connection.authFetch()
    .postJson({ ShareTo: email })
    .receive(json().map(createProjectFromApi)));
export const listProjectSharing = async (connection, projectId, userEmail) => {
    const data = await connection.authFetch()
        .url(apiV2.projects.id(projectId).sharings.url())
        .receive(json())
        .fetch();
    return data.list
        .map((item) => item.email)
        .filter((email) => email !== userEmail);
};
export const deleteProject = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).url())
    .delete()
    .receive(json())
    .send();
export const trashProject = async (connection, project) => connection.authFetch()
    .post()
    .url(apiV2.projects.id(project.id).trash.trash.url({
    accessToken: project.accessToken,
}))
    .send();
export const getProjectMessages = (connection, projectId, fromId, toId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).messageCache.url({
    fromId,
    toId,
    subsystemFilter: subSystemList.join(';'),
}))
    .receive(json())
    .fetch();
const buildExportURI = (projectId, format, withTimeStamps, isRightToLeft, exportTemplateId) => (apiV2.projects.id(projectId).export.url({
    formatId: format, withTimeStamps, exportTemplateId, isRightToLeft,
}));
export const downloadSubtitles = (connection, project, format, length, speakerSignPlacement, pauseBetweenCaptions, autofillPauseBetweenCaptions, upperCaseAllText, isRightToLeft, highlightingMode, unHighlightedColor) => connection.authFetch()
    .url(apiV2.projects.id(project.id).export.subtitles.url({
    fileFormatId: format,
    subtitleLineLength: length,
    speakerSignPlacement,
    pauseBetweenCaptionsMs: pauseBetweenCaptions * 1000, // in milisecs
    autofillPauseBetweenCaptionsMs: autofillPauseBetweenCaptions * 1000, // in milisecs
    formattingMode: 'DisableAll',
    makeAllUpperCase: upperCaseAllText,
    isRightToLeft,
    highlightingMode,
    unHighlightedColor,
}))
    .receive(blob())
    .fetch();
export const fetchExport = (connection, project, format, withTimeStamps, isRightToLeft, exportTemplateId) => connection.authFetch()
    .url(buildExportURI(project.id, format, withTimeStamps, isRightToLeft, exportTemplateId))
    .receive(blob())
    .fetch();
export const downloadSubtitlesFromLabeled = (connection, project, format, formattingMode, upperCaseAllText, keepInnerLinesStripped, isRightToLeft, highlightingMode, unHighlightedColor) => connection.authFetch()
    .url(apiV2.projects.id(project.id).export.subtitles.fromLabeled.url({
    fileFormatId: format,
    keepInnerLinesStripped,
    formattingMode,
    upperCaseAllText,
    isRightToLeft,
    highlightingMode,
    unHighlightedColor,
}))
    .receive(blob())
    .fetch();
export const labelTrsx = async (connection, project, captionParameters) => {
    const body = {
        projectId: Number(project.id),
        subtitleLineLength: captionParameters.maxLineLength,
        keepStripped: captionParameters.keepInnerLinesStripped,
        forceSingleLine: captionParameters.subtitlerMaxLineCount === 1,
        enablePresegmentation: captionParameters.enablePresegmentation,
        speakerSignPlacement: captionParameters.speakerSignPlacement,
        pauseBetweenCaptionsMs: captionParameters.pauseBetweenCaptions * 1000,
        autofillPauseBetweenCaptionsMs: captionParameters.autofillPauseBetweenCaptions * 1000,
        useSpeakerName: captionParameters.useSpeakerName,
        removeNoises: true,
        automaticSpeed: captionParameters.automaticSpeed,
        minLineDurationMs: captionParameters.minDuration * 1000,
        speakerSign: captionParameters.speakerSign,
        feMaxDurationMs: captionParameters.maxDuration * 1000,
        feSpeedWarning: captionParameters.speedWarning,
        feSpeedCriticalWarning: captionParameters.speedCriticalWarning,
        feTemplateName: captionParameters.templateName,
        defaultCaptionPosition: serializeToVttFormat(captionParameters.defaultCaptionPosition),
        defaultFontSize: String(captionParameters.defaultFontSize),
        defaultColor: captionParameters.defaultColor,
        defaultFontName: captionParameters.defaultFontName,
        defaultBackgroundColor: captionParameters.defaultBackgroundColor,
        defaultBackgroundTransparency: captionParameters.defaultBackgroundTransparency,
        upperCaseAllText: captionParameters.upperCaseAllText,
        highlightingMode: captionParameters.highlightingMode,
        unHighlightedColor: captionParameters.unHighlightedColor,
    };
    const projectFetch = connection.authFetch()
        .postJson(body)
        .receive(json().map(createProjectFromApi));
    return safelyUpdateProject(connection, apiV2.projects.id(project.id).export.subtitles.labelTrsx.url(), project, projectFetch, { currentTrsxId: project.currentTrsxId });
};
export const buildMediaManifestUrl = (project, presentationDelay) => apiV2.projects.id(project.id).files.mpdManifest.url({ presentationDelay });
export const buildMediaFileUrl = async (project, connection) => (apiV2.projects.id(project.id).files.mediaFile.url({ Authorization: await connection.retrieveAuthString() }));
export const buildLowQualityAudioUrl = async (project, connection) => (apiV2.projects.id(project.id).lowQualityAudio.url({ Authorization: await connection.retrieveAuthString() }));
export const fetchVideoSceneChange = (project, connection) => connection.authFetch()
    .url((apiV2.projects.id(project.id).files.scenes.url()))
    .receive(json())
    .fetch();
export const fetchLastError = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).lastError.url())
    .receive(json())
    .fetch();
export const createDemoProject = (connection) => connection.authFetch()
    .url(apiV2.projects.createDemoProjects.url())
    .receive(json())
    .fetch();
export const exportBasicProjects = (connection, query) => {
    const { orderon, orderby = 'descending', tags, createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.export.list.url({
        OrderOn: orderon,
        OrderBy: orderby,
        From: updatedFrom,
        To: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        Tag: tags,
    });
    return connection.authFetch()
        .url(url)
        .post()
        .receive(blob())
        .fetch();
};
export const exportSearchedProjects = async (connection, query) => {
    const { orderon, orderby = 'descending', tags, all, projectname, transcription, speakers, notes, createdFrom = '', createdTo = '', updatedFrom = '', updatedTo = '', } = query;
    const url = apiV2.projects.export.fullTextSearchList.url({
        AllFullText: all,
        ProjectNameFullText: projectname,
        TranscriptionFullText: transcription,
        SpeakersFullText: speakers,
        NotesFullText: notes,
        OrderOn: orderon,
        OrderBy: orderby,
        From: updatedFrom,
        To: updatedTo,
        CreatedFrom: createdFrom,
        CreatedTo: createdTo,
        Tag: tags,
    });
    return connection.authFetch()
        .url(url)
        .post()
        .receive(blob())
        .fetch();
};
export const exportProjects = async (connection, query) => (isSearchingProjectsQuery(query)
    ? exportSearchedProjects(connection, query)
    : exportBasicProjects(connection, query));
export const calculateTranscriptionCredit = async (connection, ...durations) => (durations.length === 0
    ? []
    : connection.authFetch()
        .url(apiV2.projects.calculateCreditToTranscribe.url({ DurationsSeconds: durations }))
        .receive(json().map((data) => data.requiredCredit))
        .fetch());
export const fetchMediaSize = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).size.url())
    .receive(json())
    .fetch();
export const fetchChecklist = async (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).checklist.url())
    .receive(json())
    .fetch();
export const updateChecklistItem = async (connection, projectId, checklistItem) => connection.authFetch()
    .url(apiV2.projects.id(projectId).checklist.id(checklistItem.id).url())
    .putJson(checklistItem)
    .receive(json().catchHttpError((response) => (response.status === 422 ? 'unprocessable-entity' : 'unexpected')))
    .fetchStrict();
export const fetchProjectRating = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).rating.url())
    .receive(json().map((data) => data.rating))
    .fetch();
export const addProjectRating = (connection, projectId, rating) => connection.authFetch()
    .url(apiV2.projects.id(projectId).rating.url())
    .postJson({ rating })
    .send();
export const unshareProject = (connection, project) => connection.authFetch()
    .url(apiV2.projects.id(project.id).unshare.url({ accessToken: project.accessToken }))
    .post()
    .send();
export const fetchProjectFiles = (connection, projectId) => connection.authFetch()
    .url(apiV2.projects.id(projectId).files.url())
    .receive(json())
    .fetch();
