import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { App, Card, Popconfirm, } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { listSpeakers, deleteSpeaker } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { useSession } from 'components/Authenticated';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust, } from 'components/ResponsiveTable';
import AddButton from 'components/AddButton';
import IconButton from 'components/IconButton';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import searchStyles from 'components/SearchInput/style.module.less';
import SearchInput from 'components/SearchInput';
import { handleSpeakersExport } from 'libs/download-csv';
import DownloadButton from 'components/DownloadButton';
import DashboardPage from '../../components/DashboardPage';
import SpeakersModal from './SpeakersModal';
import RolesTable from './RolesTable';
import styles from './style.module.less';
const SPEAKERS_TABLE_ID = 'speakers-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(215);
const SpeakersPage = () => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [speakers, setSpeakers] = useState('loading');
    const [speakersModal, setSpeakersModal] = useState({ isVisible: false, action: 'addSpeaker', speaker: null });
    const [search, setSearch] = useState('');
    const tableView = useTableView(SPEAKERS_TABLE_ID);
    const fetchSpeakers = async () => {
        const { currentPage, pageSize } = tableView.getViewParams();
        try {
            const speakersList = await listSpeakers(session.connection, pageSize * (currentPage - 1), pageSize, search !== '' ? search : undefined);
            const parsedSpeakers = speakersList.list.map((speakerXml) => {
                var _a;
                const speakerParsed = Speaker.fromXMLString(speakerXml);
                return {
                    key: (_a = speakerParsed.dbid) !== null && _a !== void 0 ? _a : speakerParsed.id,
                    firstName: speakerParsed.firstName,
                    lastName: speakerParsed.lastName,
                    role: speakerParsed.role,
                    unmaintainedMetadata: speakerParsed.unmaintainedMetadata,
                };
            });
            setSpeakers({ data: parsedSpeakers, totalCount: speakersList.totalCount });
        }
        catch (error) {
            global.logger.error('Failed to fetch speakers', {}, error);
            void message.error(txt('failedToFetchSpeaker'));
        }
    };
    const handleDeleteRow = async (key) => {
        if (speakers === 'loading')
            return;
        if (key !== '') {
            try {
                await deleteSpeaker(session.connection, key);
                const { currentPage, pageSize } = tableView.getViewParams();
                const isLastSpeaker = speakers.totalCount % pageSize === 1;
                if (currentPage > 1 && isLastSpeaker) {
                    tableView.setCurrentPage(currentPage - 1);
                }
                else {
                    await fetchSpeakers();
                }
                void message.success(txt('speakerDeleted'), 2);
            }
            catch (error) {
                global.logger.error('Failed to delete speaker from the database', {}, error);
                void message.error(txt('failedToDeleteSpeaker'));
            }
        }
    };
    const handleSearch = (query) => {
        tableView.setCurrentPage(1);
        setSearch(query);
    };
    const headerNames = ['firstName', 'lastName', 'role'];
    const headerText = headerNames.map((name) => ({
        title: txt(name),
        dataIndex: name,
        className: 'name-cell',
    }));
    const columns = [
        ...headerText,
        {
            title: '',
            dataIndex: 'editSpeaker',
            fixed: 'right',
            width: '40px',
            render: (_, record) => (_jsx(IconButton, { onClick: () => setSpeakersModal({ isVisible: true, action: 'editSpeaker', speaker: record }), children: _jsx(EditOutlined, {}) })),
        },
        {
            title: '',
            dataIndex: 'deleteSpeaker',
            fixed: 'right',
            width: '40px',
            render: (_, record) => (_jsx(Popconfirm, { title: txt('deleteSpeaker'), onConfirm: async () => handleDeleteRow(record.key), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, cancelText: txt('cancel'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) })),
        },
    ];
    return (_jsx(DashboardPage, { title: txt('speakers'), children: ({ contentHeight }) => (_jsxs("section", { className: styles.speakersPage, children: [_jsxs(Card, { bordered: false, children: [_jsxs("div", { className: styles.speakersTable, children: [_jsxs("div", { className: clsx(searchStyles.toolbar, searchStyles.fullWidthToolbar), children: [_jsx(AddButton, { title: txt('addSpeaker'), onClick: () => setSpeakersModal({ isVisible: true, action: 'addSpeaker', speaker: null }) }), _jsx(SearchInput, { placeholder: txt('findSpeaker'), onSearch: handleSearch }), _jsx(DownloadButton, { onHandleExport: async () => handleSpeakersExport(session.connection), title: txt('downloadInCSV') })] }), _jsx(ResponsiveTable, { tableView: tableView, onViewChange: fetchSpeakers, dataSource: speakers !== 'loading' ? speakers.data : [], recordCount: speakers !== 'loading' ? speakers.totalCount : 0, columns: columns, rowKey: (speaker) => speaker.key, loading: speakers === 'loading', scroll: { y: contentHeight - SCROLL_HEIGHT_ADJUST }, stripeRows: true })] }), _jsx(RolesTable, { contentHeight: contentHeight })] }), _jsx(SpeakersModal, { visible: speakersModal.isVisible, action: speakersModal.action, onClose: () => setSpeakersModal({ isVisible: false, action: 'addSpeaker', speaker: null }), speaker: speakersModal.speaker, onUpdateTable: fetchSpeakers })] })) }));
};
export default SpeakersPage;
